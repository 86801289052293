import { NextApiRequest, NextApiResponse } from 'next';
import { apiError, BASE_URL } from '../../../pages/api/utils';
import {
  CHECKOUT_ERROR_FRAGMENT,
  CHECKOUT_FRAGMENT,
  ShopifyFragmentsNames,
  shopifyStoreFront,
  transformCheckout
} from '../../../pages/api/utils/shopifyApi';
import { CheckoutRes } from '../../../types/store';

const removeGiftCardQuery = `
${CHECKOUT_FRAGMENT}
${CHECKOUT_ERROR_FRAGMENT}
mutation checkoutGiftCardRemoveV2($appliedGiftCardId: ID!, $checkoutId: ID!) {
  checkoutGiftCardRemoveV2(appliedGiftCardId: $appliedGiftCardId, checkoutId: $checkoutId) {
    checkout {
      ...${ShopifyFragmentsNames.CHECKOUT_FRAGMENT}
    }
    checkoutUserErrors {
      ...${ShopifyFragmentsNames.CHECKOUT_ERROR_FRAGMENT}
    }  
  }
}
`;

const shopifyRemoveGiftCard = async (req: NextApiRequest, res: NextApiResponse) => {
  const { body } = req;

  try {
    let response = await shopifyStoreFront(removeGiftCardQuery, JSON.parse(body));

    res.status(200);
    res.json(transformCheckout(response?.checkoutGiftCardRemoveV2));
  } catch (error) {
    apiError({ errorInText: 'remove gift card - checkout', error, res, resJsonKey: 'checkoutUserErrors' });
  }
};

export interface RemoveGiftCardBody {
  checkoutId: string;
  giftCardCodes: string[];
}

export const removeGiftCard = async (body: RemoveGiftCardBody): Promise<CheckoutRes | undefined> => {
  try {
    const response = await fetch(`${BASE_URL}/api/shopify/checkout/remove-gift-card`, {
      method: 'POST',
      body: JSON.stringify(body)
    });

    const resJson = await response?.json();

    return resJson;
  } catch (e) {
    console.error('removeGiftCard failed', e);
  }
};

export default shopifyRemoveGiftCard;
