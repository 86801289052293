import * as React from 'react';
import { Sprinkles } from '../../sprinkles.css';
import { Box, PolymorphicComponentProps } from './Box';
import { textRecipe, TextVariants } from './text.css';

type TextOwnProps = TextVariants & {
  disableLinkStyle?: boolean;
  align?: Sprinkles['textAlign'];
  weight?: Sprinkles['fontWeight'];
  size?: Sprinkles['fontSize'];
};

export type TextProps<E extends React.ElementType> = PolymorphicComponentProps<E, TextOwnProps>;

const defaultElement = 'p';

export const Text: <E extends React.ElementType = typeof defaultElement>(
  props: TextProps<E>
) => React.ReactElement | null = React.forwardRef(
  <E extends React.ElementType = typeof defaultElement>(
    {
      align = 'left',
      weight = 'normal',
      fontSize,
      variant,
      color,
      disableLinkStyle,
      className,
      children,
      ...restProps
    }: TextProps<E>,
    ref: typeof restProps.ref
  ) => {
    // Use `variant='unstyled'` so bypass recipe. This allows for full customization
    // via style props. Note that the `unstyled` variant is not part of the recipe and
    // is only used here as a flag to bypass the recipe.
    const noVariant = variant === 'unstyled' || !variant;
    let _className = noVariant ? '' : textRecipe({ variant });

    const isLink = (restProps.to || restProps.href) && !disableLinkStyle;

    const isHigherHeading = restProps?.as === 'h1' || restProps?.as === 'h2';

    return (
      <Box
        ref={ref}
        className={[_className, className].join(' ')}
        as={defaultElement}
        fontSize={fontSize}
        fontFamily="body"
        lineHeight="base"
        fontWeight={weight}
        letterSpacing="normal"
        color={!color && isHigherHeading ? 'primary' : color ?? 'inherit'}
        textAlign={align}
        {...restProps}>
        {children}
      </Box>
    );
  }
);
