import { usePiwikPro } from '@piwikpro/next-piwik-pro';

const useAnalytics = () => {
  const { CustomEvent, eCommerce, ContentTracking, GoalConversions, SiteSearch } = usePiwikPro();

  return {
    trackCustomEvent: CustomEvent.trackEvent,
    addItemToCartTracking: eCommerce.addEcommerceItem,
    removeItemFromCartTracking: eCommerce.removeEcommerceItem,
    updateCartTracking: eCommerce.trackEcommerceCartUpdate,
    checkoutTrack: eCommerce.trackEcommerceOrder,
    trackContentInteraction: ContentTracking?.trackContentInteraction,

    trackGoal: GoalConversions.trackGoal,
    trackSearch: SiteSearch.trackSiteSearch
  };
};

export default useAnalytics;
