import { observer } from 'mobx-react';
import { ReactNode, useEffect } from 'react';
import { getContentfulEntry } from '../pages/api/contentful/get-entry';
import { GlobalAnnouncementBar, MainGlobalContentfulData } from '../pages/api/utils/contentful';
import { originIsNotProd } from '../store/rootStore';
import { useRootStore } from '../store/rootStoreProvider';
import { restrictedScreenLogo } from '../styles/index.css';
import { Box, Button, Flex, Stack, Text } from './Design';
import { Alert } from './Design/Primitives/components/Alert';
import Modal from './Design/Starters/Modal';
import MailingListForm from './Forms/MailingListForm';
import Logo from './Logo';

export interface AppConfigProps {
  children: ReactNode;
}

const AppConfig = ({ children }: AppConfigProps) => {
  const { globalError, setGlobalError, isRestricted, announcementBar, updateAnnouncementBar } = useRootStore();

  const onCloseInternal = () => {
    setGlobalError(undefined);
  };

  useEffect(() => {
    let mounted = true;
    if (!mounted) return;

    const fetchAnnouncementBar = async () => {
      try {
        const globalCMSContent: { global: MainGlobalContentfulData | null } = await getContentfulEntry('global', false);

        const newAnnouncementBar = globalCMSContent?.global?.globalDataCollection?.items?.find(({ name }) =>
          name?.toLocaleLowerCase().includes('announcement')
        ) as GlobalAnnouncementBar;

        if (JSON.stringify(announcementBar) !== JSON.stringify(newAnnouncementBar))
          updateAnnouncementBar(newAnnouncementBar);
      } catch (error) {
        console.error('Error fetching announcementBar:', error);
      }
    };

    fetchAnnouncementBar();

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <>
      <Modal onClose={onCloseInternal} isOpen={!!globalError} maxWidth="450px">
        <Stack>
          <Alert status="error">{globalError}</Alert>
          <Flex justify="flex-end">
            <Button onClick={onCloseInternal}>Close</Button>
          </Flex>
        </Stack>
      </Modal>
      <Box height="auto" width="full">
        {isRestricted ? <SiteDownScreen controls={undefined} /> : children}
      </Box>
    </>
  );
};

export default observer(AppConfig);

export interface SiteDownScreenProps {
  controls?: {
    message?: string;
  };
}

export const SiteDownScreen = ({ controls }: SiteDownScreenProps) => {
  return (
    <>
      <Box style={{ width: '100vw', height: '100vh', paddingTop: '14vh' }} position="relative" paddingX="12px">
        <Stack variant="sectionStack" justify="center">
          <Box>
            <Box width="fitContent" margin="auto">
              <Logo style={{ marginLeft: '-20px' }} logoClass={restrictedScreenLogo} disableLink />
            </Box>
            <Text as="h1" variant="sectionHeading" width="fitContent" margin="auto">
              {originIsNotProd ? 'This site has restricted access' : ' We are currently under construction'}
            </Text>
          </Box>
          {controls?.message && <Text>{controls?.message}</Text>}
          {!originIsNotProd && (
            <MailingListForm
              margin="auto"
              maxWidth="400px"
              inputID="construction-screen"
              label="Sign up to receive email updates from us!"
              labelProps={{ textAlign: 'center', marginBottom: '4px' }}
            />
          )}

          <Text variant="caption" width="fitContent" margin="auto">
            Copyright © {new Date().getFullYear()} Aeribella, LLC All rights reserved.
          </Text>
        </Stack>
      </Box>
    </>
  );
};
