import * as React from 'react';
import { Box, PolymorphicComponentProps } from './Box';
import { InputVariants, iconContainer, inputRecipe } from './input.css';

type StyleProps = {} & InputVariants;

type UVariant<T = StyleProps> = T extends InputVariants ? Omit<T, 'variant'> : never;

type InputOwnProps = UVariant & {
  icon?: React.ReactNode | null;
  disabled?: boolean;
  variant?: 'unstyled' | StyleProps['variant'];
  hasError?: boolean;
  // autocomplete Ref https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
  autocomplete?:
    | 'off'
    | 'on'
    | 'name'
    | 'given-name'
    | 'family-name'
    | 'email'
    | 'username'
    | 'new-password'
    | 'current-password'
    | 'address-line1'
    | 'address-line2'
    | 'postal-code'
    | 'country-name'
    | 'cc-name'
    | 'cc-number'
    | 'cc-exp'
    | 'cc-csc'
    | 'tel';
};

export type InputProps<E extends React.ElementType> = PolymorphicComponentProps<E, InputOwnProps>;

const defaultElement = 'input';

export const Input: <E extends React.ElementType = typeof defaultElement>(
  props: InputProps<E>
) => React.ReactElement | null = React.forwardRef(
  <E extends React.ElementType = typeof defaultElement>(
    {
      variant,
      size,
      colorScheme,
      width = 'full',
      focusVisible = false,
      hasError,
      children,
      disabled = false,
      icon = null,
      className,
      id,
      name,
      autocomplete,
      ...restProps
    }: InputProps<E>,
    ref: typeof restProps.ref
  ) => {
    const inputClassName = inputRecipe({
      variant,
      colorScheme,
      size,
      focusVisible,
      error: hasError,
      disabled
    });

    return (
      <Box as="span" position="relative" width={width} flex="1">
        {/* @ts-ignore */}
        <Box
          as="input"
          display="block"
          fontFamily="body"
          id={id ?? name}
          name={name}
          autoComplete={autocomplete}
          disabled={disabled}
          className={[inputClassName, className].join(' ')}
          {...restProps}
          ref={ref}
          paddingRight={restProps.type === 'password' ? '44px' : undefined}
          {...(icon && { style: { ...restProps?.style, paddingLeft: '44px !important' } })}
        />
        {icon && <Box className={iconContainer}>{icon}</Box>}
      </Box>
    );
  }
);
