import { NextRequest } from 'next/server';
import { apiError, BASE_URL } from '../utils';
import { ContentfulQueryOptions, fetchContentfulContentData } from '../utils/contentful';

export const config = {
  runtime: 'edge'
};
//  res: NextApiResponse
const contentfulEntry = async (req: NextRequest) => {
  const { searchParams } = new URL(req.url);
  const queryName = searchParams.get('queryName');
  const cache = searchParams.get('cache');

  try {
    // Get Entry
    const getEntry = await fetchContentfulContentData({ query: queryName as ContentfulQueryOptions });
    const cacheConfig = cache === 'true' ? 's-maxage=86400, stale-while-revalidate' : 'no-store';

    return new Response(JSON.stringify(getEntry), {
      status: 200,
      headers: {
        'content-type': 'application/json',
        // 10 Minutes Caching
        'cache-control': cacheConfig
      }
    });
  } catch (error) {
    apiError({ errorInText: 'Get Contentful Entry', error, isEdge: true });
  }
};

export const getContentfulEntry = async (queryName: ContentfulQueryOptions, cache?: boolean) => {
  try {
    const response = await fetch(`${BASE_URL}/api/contentful/get-entry?queryName=${queryName}&cache=${cache ?? false}`);

    const jsonRes = await response?.json();

    if (jsonRes?.errors) {
      throw jsonRes;
    }

    return jsonRes;
  } catch (e: any) {
    console.error('getContentfulEntry failed', e);
    if (e) return e;
  }
};

export default contentfulEntry;
