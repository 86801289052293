import { UnknownDict } from '../global';
import { shopifyTagClean } from '../pages/api/utils/shopifyApi';
import { ProductData } from '../types/store';
import { ErrorWithTextMessage } from '../types/utils';
import { CARD_TYPES_CODE, CARD_TYPES_NAMES } from './const';

/**
 * Call requestIdleCallback in supported browsers.
 *
 * https://developers.google.com/web/updates/2015/08/using-requestidlecallback
 * http://caniuse.com/#feat=requestidlecallback
 */
export const requestIdleCallback = (fn: () => void | IdleRequestCallback) => {
  if ('requestIdleCallback' in window) {
    return window.requestIdleCallback(fn);
  } else {
    return setTimeout(() => fn(), 1);
  }
};

export const watchOnlineStatus = (callback: (bool: boolean) => void, win: Window & typeof globalThis = window) => {
  const off = () => callback(false);
  const on = () => callback(true);
  win.addEventListener('offline', off);
  win.addEventListener('online', on);
  const unsubscribe = () => {
    win.removeEventListener('offline', off);
    win.removeEventListener('online', on);
  };
  return unsubscribe;
};

/**
 * Performs a shallow comparison on two objects
 */
export const shallowEquals = (a: UnknownDict, b: UnknownDict) => {
  for (let key in a) {
    if (!(key in b) || a[key] !== b[key]) {
      return false;
    }
  }
  for (let key in b) {
    if (!(key in a) || a[key] !== b[key]) {
      return false;
    }
  }
  return true;
};

/**
 * No operation function. You can use this
 * empty function when you wish to pass
 * around a function that will do nothing.
 * Usually used as default for event handlers.
 */
export const noop = () => {};

/**
 * Flattens a tree data structure into an array.
 */
export const flatten = (node: UnknownDict, key: string = 'children') => {
  const children = (node[key] || []).reduce((a: UnknownDict, b: UnknownDict) => {
    return Array.isArray(b[key]) && !!b[key].length ? { ...a, ...flatten(b, key) } : { ...a, [b.id]: b };
  }, {});

  return {
    [node.id]: node,
    ...children
  };
};

/**
 * Check the current execution environment
 * is client side or server side
 */
export const isServer = typeof window === 'undefined';

/**
 * retrieves an item from session storage
 */
export const getSessionJSONItem = (key: string): UnknownDict | undefined => {
  if (isServer) {
    return undefined;
  }
  const item = window.sessionStorage.getItem(key);
  if (item) {
    return JSON.parse(item);
  } else {
    return undefined;
  }
};
/**
 * sets an item in session storage
 */
export const setSessionJSONItem = (key: string, value: string) => {
  window.sessionStorage.setItem(key, JSON.stringify(value));
};

/**
 * clears an item in session storage
 * @param {string} key
 */
export const clearSessionJSONItem = (key: string) => {
  window.sessionStorage.removeItem(key);
};

/**
 * bolds a substring of a string by adding <b> tags
 */
export const boldString = (str: string, substr: string) => {
  return str.replace(RegExp(substr, 'g'), `<b>${substr}</b>`);
};

/**
 * Capitalizes the words in a string
 */
export const capitalize = (text?: string) => {
  return text
    ?.toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
};

function isErrorWithTextMessage(error: unknown): error is ErrorWithTextMessage {
  return (
    typeof error !== 'object' &&
    error != null &&
    (error as Record<string, unknown>).hasOwnProperty('message') &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
}

export function getErrorMessage(error: unknown): string {
  if (isErrorWithTextMessage(error)) return error.message;

  return toErrorWithMessage(error).message;
}

function toErrorWithMessage(maybeError: unknown): ErrorWithTextMessage {
  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    return new Error(String(maybeError));
  }
}

export const cleanSearchQuery = (query?: string) => {
  return (
    (query?.includes(':') || query?.includes('-') ? shopifyTagClean([query])?.[0] : query)
      ?.trim()
      ?.toLocaleLowerCase() ?? ''
  );
};

export const getSearchValueFromSearchQuery = (input?: string) => {
  if (!input) return undefined;

  const cleanedString = input
    .replace(/tag:".*?"/g, '')
    .replace(/["']/g, '')
    .trim();
  const keywords = cleanedString.split(' ').filter((word) => !word.includes('tag:') && !word.includes('variants.'));
  const cleanedKeyword = keywords.join(' ');
  return cleanedKeyword || '';
};

export const getOffsetDate = (numberOfDaysToAdd?: number) => {
  if (!numberOfDaysToAdd) return;
  const currentDate = new Date();
  const result = new Date().setDate(currentDate.getDate() - 14);
  const offsetDate = new Date(result);

  return { currentDate, offsetDate };
};

export const convertProductArrayToByHandle = (productsRes: ProductData | ProductData[]) => {
  const productsByHandle: Record<string, ProductData> = {};

  if (productsRes && Array.isArray(productsRes)) {
    productsRes?.forEach((product) => {
      if (product.handle) productsByHandle[product.handle] = product;
    });

    return productsByHandle;
  }

  return undefined;
};

export const scrollToTargetAdjusted = (element: HTMLElement, customOffset?: number) => {
  var headerOffset = customOffset ?? 20;
  var elementPosition = element.getBoundingClientRect().top;
  var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  });
};

export const convertCardTypeCodeToCardName = (type?: CARD_TYPES_CODE): string | undefined => {
  if (!type) {
    return undefined;
  }

  return CARD_TYPES_NAMES[type];
};

export const toFixedHandler = (number?: string | number) => {
  switch (typeof number) {
    case 'string':
      return (+number).toFixed(2);

    default:
      return number?.toFixed(2);
  }
};

export const splitStringOnForwardSlash = (str: string) => str?.split('/')?.[str?.split('/')?.length - 1];

export const isExpiredDate = (date: Date, unit: 'days' | 'hours' | 'min', length: number) => {
  if (!date) return;

  const pastDate = new Date(date);
  const now = new Date();
  const unitSelector = {
    days: length * 24 * 60 * 60 * 1000,
    hours: 1 * length * 60 * 60 * 1000,
    min: 1 * 24 * length * 60 * 1000
  };
  const periodOfTime = unitSelector[unit];
  const timeDiffInMs = now.getTime() - pastDate.getTime();

  return timeDiffInMs >= periodOfTime ? true : false;
};

export const isInViewport = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const isWarmerMonths = () => {
  const allMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const d = new Date();
  const currentMonth = allMonths[d.getMonth()];

  return ['May', 'June', 'July', 'August', 'September'].includes(currentMonth);
};

export const dateHasPassed = (dateToCheck: Date | { month: string; day: number; year: number }) => {
  const currentDate = new Date();
  let targetDate: Date;

  if (dateToCheck instanceof Date || typeof dateToCheck === 'string') {
    targetDate = new Date(dateToCheck);
  } else {
    const { month, day, year } = dateToCheck;
    targetDate = new Date(`${month} ${day}, ${year}`);
  }

  return targetDate <= currentDate;
};

export const removeLineBreaks = (str?: string) => {
  return str?.replace(/(\r\n|\n|\r)/gm, '');
};
