import { action, computed, flow, makeObservable, observable } from 'mobx';
import { AddToCartBody, addToCart } from '../pages/api/shopify/cart/add-to-cart';
import { getCart } from '../pages/api/shopify/cart/cart';
import {
  UpdateCartDiscountCodeBody,
  updateCartDiscountCode
} from '../pages/api/shopify/cart/cart-discount-code-update';
import { createShopifyCart } from '../pages/api/shopify/cart/create-cart';
import { RemoveFromCartBody, removeFromCart } from '../pages/api/shopify/cart/remove-from-cart';
import { UpdateCartItemBody, updateCartItem } from '../pages/api/shopify/cart/update-cart-item';
import { CartData, CreateCartData, StoreModel, UserErrorData } from '../types/store';
import { apiErrorMessage } from '../utils/const';
import { RootStore } from './rootStore';

export interface CartHydration {
  cartId?: string;
  loading?: boolean;
  cart?: CartData | null;
}

export class CartStore implements StoreModel {
  cart: CartHydration['cart'];
  loading: CartHydration['loading'];
  rootStore: RootStore;

  constructor(
    rootStore: RootStore,
    initialState: CartHydration = {
      cart: undefined,
      loading: false
    }
  ) {
    this.cart = initialState.cart;
    this.loading = initialState.loading;
    this.rootStore = rootStore;

    makeObservable(this, {
      cartId: computed,
      loading: observable,
      cart: observable,
      cartError: computed,
      createCart: flow.bound,
      getCart: flow.bound,
      addToCart: flow.bound,
      updateCartItem: flow.bound,
      removeCartItem: flow.bound,
      addCartDiscountCode: flow.bound,
      hydrate: action
    });
  }

  get asJson() {
    return {
      cartId: this.cartId,
      cart: this.cart,
      loading: this.loading
    };
  }

  get cartId() {
    return this?.cart?.id;
  }

  get cartError() {
    return (this.cart as { userErrors: UserErrorData[] } | null | undefined)?.userErrors;
  }

  hydrate(data?: CartHydration) {
    if (data) {
      this.cart = data.cart;
    }
  }

  *createCart() {
    if (typeof window === 'undefined') return;
    this.loading = true;

    const localCartID = localStorage.getItem('cartID');

    let cart: CreateCartData | undefined = !localCartID
      ? yield createShopifyCart()
      : yield this.getCart(localCartID, true);

    if (!cart) {
      // Fallback if getCart Fails
      cart = yield createShopifyCart();
    }

    if (cart instanceof Error) {
      this.rootStore.setGlobalError(apiErrorMessage('creating your cart'));
      this.loading = false;
      throw cart;
    }

    if (cart?.id || localCartID) {
      cart?.id && localStorage.setItem('cartID', cart?.id);
    }

    if (!this.cart || this.cartId !== this?.cart?.id) {
      this.cart = cart as CartHydration['cart'];
    }

    this.loading = false;

    return this.cart;
  }

  *getCart(id: string, disableError?: boolean) {
    this.loading = true;
    const cart: CartData | undefined = yield getCart(id);

    if (cart instanceof Error && !disableError) {
      this.rootStore.setGlobalError(apiErrorMessage('retrieving your cart'));
      this.loading = false;
      throw cart;
    }

    if (cart) {
      this.cart = cart;
    }

    this.loading = false;

    return this.cart;
  }

  *addToCart(body: AddToCartBody) {
    this.loading = true;
    const cart: CartData | undefined = yield addToCart(body);

    if (cart instanceof Error) {
      this.rootStore.setGlobalError(apiErrorMessage('adding to your cart'));
      this.loading = false;
      throw cart;
    }

    if (cart) {
      this.cart = cart;
    }

    this.loading = false;
  }

  *updateCartItem(body: UpdateCartItemBody) {
    this.loading = true;
    const cart: CartData | undefined = yield updateCartItem(body);

    if (cart instanceof Error) {
      this.rootStore.setGlobalError(apiErrorMessage('updating your cart'));
      this.loading = false;
      throw cart;
    }

    if (cart) {
      this.cart = cart;
    }

    this.loading = false;
  }

  *removeCartItem(body: RemoveFromCartBody) {
    this.loading = true;
    const cart: CartData | undefined = yield removeFromCart(body);

    if (cart instanceof Error) {
      this.rootStore.setGlobalError(apiErrorMessage('removing from your cart'));
      this.loading = false;
      throw cart;
    }

    if (cart) {
      this.cart = cart;
    }

    this.loading = false;
  }

  *addCartDiscountCode(body: UpdateCartDiscountCodeBody) {
    this.loading = true;

    const cart: CartData | undefined = yield updateCartDiscountCode(body);

    if (cart instanceof Error) {
      this.rootStore.setGlobalError(apiErrorMessage('adding discount code'));
      this.loading = false;
      throw cart;
    }

    if (cart) {
      this.cart = cart;
    }

    this.loading = false;
  }
}
