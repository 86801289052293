import { ReactNode, useState } from 'react';
import { Controller, ControllerProps, FieldValues, UseFormReset, UseFormSetError, useForm } from 'react-hook-form';
import { UnknownDict } from '../../../global';
import { CheckCircleIcon } from '../Icons';
import { InputProps } from '../Primitives/components/Input';
import { TextProps } from '../Primitives/components/Text';
import { colors } from '../vars.css';

import { Box, BoxProps, Button, Flex, Input, Stack, Text } from './../';

export interface InlineFormFieldParams extends Pick<ControllerProps, 'name' | 'rules'> {
  placeholder: string;
  autocomplete?: InputProps<'input'>['autocomplete'];
}

export interface InlineFormOnSubmitTools {
  setError: UseFormSetError<FieldValues>;
  reset: UseFormReset<FieldValues>;
}

export interface InlineFormProps extends Omit<BoxProps<'form'>, 'onSubmit'> {
  stacked?: boolean;
  buttonColor?: 'primary' | 'secondary' | 'tertiary';
  buttonChildren: ReactNode;
  labelProps?: Omit<TextProps<'label'>, 'htmlForm' | 'as' | 'fontSize'>;
  label?: ReactNode;
  onSubmit: (data: UnknownDict, tools?: InlineFormOnSubmitTools) => void;
  fieldParams: InlineFormFieldParams;
  forcedErrorText?: ReactNode;
  hideLabel?: boolean;
  resetOnSubmit?: boolean;
  rules?: ControllerProps['rules'];
  flipErrorColors?: boolean;
  success?: boolean;
  inputID: string;
}

const InlineForm = ({
  stacked,
  buttonColor = 'primary',
  labelProps,
  label,
  onSubmit,
  buttonChildren,
  fieldParams,
  forcedErrorText,
  hideLabel,
  resetOnSubmit,
  flipErrorColors,
  success,
  inputID,
  ...props
}: InlineFormProps) => {
  const { control, handleSubmit, reset, setError } = useForm({ defaultValues: { [fieldParams?.name]: '' } });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { placeholder } = fieldParams;

  const onInternalSubmit = async (data: UnknownDict) => {
    setIsLoading(true);
    await onSubmit(data, { setError, reset });

    if (resetOnSubmit) {
      reset({
        [fieldParams.name]: ''
      });
    }

    setIsLoading(false);
  };

  return (
    <Box as="form" width="full" onSubmit={handleSubmit(onInternalSubmit)} {...props}>
      <Stack width="full" spacing="8px" position={hideLabel ? 'relative' : undefined}>
        <Text
          as="label"
          htmlFor={`${fieldParams.name}-${inputID}`}
          fontSize="lg"
          textTransform="capitalize"
          {...(hideLabel && {
            position: 'absolute',
            top: '0px',
            left: '0px',
            zIndex: 'hide'
          })}
          {...labelProps}>
          {label ? label : 'Get updates and special offers:'}
        </Text>
        <Box as="span">
          <Controller
            {...fieldParams}
            control={control}
            render={({ field, fieldState }) => (
              <Box as="span">
                <Flex as="span" direction={stacked ? 'column' : 'row'} flex="1" width={stacked ? 'full' : undefined}>
                  <Stack as="span" spacing="4px" flex="1" width="full">
                    <Input
                      name={field.name}
                      id={`${field.name}-${inputID}`}
                      value={field.value}
                      icon={success && <CheckCircleIcon color={colors.success} size="32px" />}
                      onChange={field.onChange}
                      placeholder={placeholder}
                      hasError={!!forcedErrorText || !!fieldState?.error}
                      style={
                        stacked
                          ? {
                              borderBottomRightRadius: '0px',
                              borderBottomLeftRadius: '0px'
                            }
                          : {
                              borderBottomRightRadius: '0px',
                              borderTopRightRadius: '0px'
                            }
                      }
                    />
                    {(forcedErrorText || fieldState?.error) && (
                      <Text
                        as="span"
                        color={flipErrorColors ? 'white' : 'error'}
                        backgroundColor={flipErrorColors ? 'error' : 'white'}
                        padding="4px"
                        borderRadius="base"
                        fontSize="sm">
                        {forcedErrorText ?? fieldState.error?.message}
                      </Text>
                    )}
                  </Stack>
                  <Button
                    type="submit"
                    variant={buttonColor}
                    isLoading={isLoading}
                    width={stacked ? 'full' : undefined}
                    style={
                      stacked
                        ? {
                            borderTopRightRadius: '0px',
                            borderTopLeftRadius: '0px'
                          }
                        : {
                            borderBottomLeftRadius: '0px',
                            borderTopLeftRadius: '0px'
                          }
                    }>
                    {buttonChildren}
                  </Button>
                </Flex>
              </Box>
            )}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default InlineForm;
