import { NextApiRequest, NextApiResponse } from 'next';
import { apiError, BASE_URL } from '../../../pages/api/utils';
import {
  CHECKOUT_ERROR_FRAGMENT,
  CHECKOUT_FRAGMENT,
  ShopifyFragmentsNames,
  shopifyStoreFront,
  transformCheckout
} from '../../../pages/api/utils/shopifyApi';
import { CheckoutRes } from '../../../types/store';

const removeDiscountCodeQuery = `
${CHECKOUT_FRAGMENT}
${CHECKOUT_ERROR_FRAGMENT}
mutation checkoutDiscountCodeRemove($checkoutId: ID!) {
  checkoutDiscountCodeRemove(checkoutId: $checkoutId) {
    checkout {
      ...${ShopifyFragmentsNames.CHECKOUT_FRAGMENT}
    }
    checkoutUserErrors {
      ...${ShopifyFragmentsNames.CHECKOUT_ERROR_FRAGMENT}
    }  
  }
}
`;

const shopifyRemoveDiscountCode = async (req: NextApiRequest, res: NextApiResponse) => {
  const { checkoutId, discountCode } = req?.query;

  try {
    let response = await shopifyStoreFront(removeDiscountCodeQuery, {
      checkoutId,
      discountCode
    });

    res.status(200);
    res.json(transformCheckout(response?.checkoutDiscountCodeRemove));
  } catch (error) {
    apiError({ errorInText: 'remove discount code - checkout', error, res, resJsonKey: 'checkoutUserErrors' });
  }
};

export const removeDiscountCode = async (checkoutId: string): Promise<CheckoutRes | undefined> => {
  try {
    const response = await fetch(`${BASE_URL}/api/shopify/checkout/remove-discount-code?checkoutId=${checkoutId}`);

    const resJson = await response?.json();

    return resJson;
  } catch (e) {
    console.error('removeDiscountCode failed', e);
  }
};

export default shopifyRemoveDiscountCode;
