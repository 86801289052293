import { NextApiRequest, NextApiResponse } from 'next';
import { apiError, BASE_URL } from '../../../pages/api/utils';
import {
  CHECKOUT_FRAGMENT,
  ShopifyFragmentsNames,
  shopifyStoreFront,
  transformCheckout
} from '../../../pages/api/utils/shopifyApi';
import { CheckoutData } from '../../../types/store';

const getCheckoutByIdQuery = (id: string) => `
${CHECKOUT_FRAGMENT}
{
  node(id: "${id}") {
    id
    ... on Checkout {
      id
      ...${ShopifyFragmentsNames.CHECKOUT_FRAGMENT}
    }
  }
}
`;

const shopifyGetCheckoutById = async (req: NextApiRequest, res: NextApiResponse) => {
  const { id } = req?.query;
  const query = getCheckoutByIdQuery(id as string);

  try {
    let response = await shopifyStoreFront(query);

    res.status(200);
    res.json(transformCheckout({ checkout: response?.node })?.checkout);
  } catch (error) {
    apiError({ errorInText: 'get checkout by id', error, res, resJsonKey: 'checkoutUserErrors' });
  }
};

export const getCheckoutById = async (id: string): Promise<CheckoutData | undefined> => {
  try {
    const response = await fetch(`${BASE_URL}/api/shopify/checkout/get-checkout-by-id?id=${id}`);

    const resJson = await response?.json();

    return resJson;
  } catch (e) {
    console.error('getCheckoutById failed', e);
  }
};

export default shopifyGetCheckoutById;
