import { NextApiRequest, NextApiResponse } from 'next';
import { apiError, BASE_URL } from '../../../pages/api/utils';
import {
  CHECKOUT_ERROR_FRAGMENT,
  CHECKOUT_FRAGMENT,
  ShopifyFragmentsNames,
  shopifyStoreFront,
  transformCheckout
} from '../../../pages/api/utils/shopifyApi';
import { CheckoutRes } from '../../../types/store';

const updateEmailQuery = `
${CHECKOUT_FRAGMENT}
${CHECKOUT_ERROR_FRAGMENT}
mutation checkoutEmailUpdateV2($checkoutId: ID!, $email: String!) {
  checkoutEmailUpdateV2(checkoutId: $checkoutId, email: $email) {
    checkout {
      ...${ShopifyFragmentsNames.CHECKOUT_FRAGMENT}
    }
    checkoutUserErrors {
      ...${ShopifyFragmentsNames.CHECKOUT_ERROR_FRAGMENT}
    }  
  }
}
`;

const shopifyUpdateEmail = async (req: NextApiRequest, res: NextApiResponse) => {
  const { checkoutId, email } = req?.query;

  try {
    let response = await shopifyStoreFront(updateEmailQuery, { checkoutId, email });

    res.status(200);
    res.json(transformCheckout(response?.checkoutEmailUpdateV2));
  } catch (error) {
    apiError({ errorInText: 'update email - checkout', error, res, resJsonKey: 'checkoutUserErrors' });
  }
};

export const updateEmail = async (checkoutId: string, email: string): Promise<CheckoutRes | undefined> => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/shopify/checkout/update-email?checkoutId=${checkoutId}&email=${email}`
    );

    const resJson = await response?.json();

    return resJson;
  } catch (e) {
    console.error('updateEmail failed', e);
  }
};

export default shopifyUpdateEmail;
