import * as React from 'react';
import { Box, PolymorphicComponentProps } from './Box';
import { container, ContainerVariants } from './container.css';

type ContainerOwnProps = ContainerVariants;
export type ContainerProps<E extends React.ElementType> = PolymorphicComponentProps<E, ContainerOwnProps>;

const defaultElement = 'div';

export const Container: <E extends React.ElementType = typeof defaultElement>(
  props: ContainerProps<E>
) => React.ReactElement | null = React.forwardRef(
  <E extends React.ElementType = typeof defaultElement>(
    {
      children,
      size = 'xxl',
      paddingX = { mobile: '12px', tablet: '16px', desktop: '24px' },
      ...restProps
    }: ContainerProps<E>,
    ref: typeof restProps.ref
  ) => {
    const className = container({ size });

    return (
      <Box as={defaultElement} className={className} paddingX={paddingX} ref={ref} {...restProps}>
        {children}
      </Box>
    );
  }
);
