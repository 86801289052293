import { Document } from '@contentful/rich-text-types';
import { apiError } from '.';
import { UnknownDict } from '../../../global';
import { dateHasPassed } from '../../../utils';

export interface ContentfulImageData {
  __typename: string;
  title: string;
  description: string;
  url: string;
  width?: number;
  height?: number;
}

export interface ContentfulImageCollectionData {
  __typename: string;
  image: {
    title: string;
    description: string;
    url: string;
  };
}

export interface ContentfulTextData {
  __typename: string;
  name: string;
  text: string;
}

export interface ContentfulAnnouncementData {
  __typename: string;
  name: string;
  announcement: string;
  showAnnouncement: boolean;
  bannerTextColor?: {
    id: string;
    name: string;
    value: string;
  };
  bannerBackgroundColor?: {
    id: string;
    name: string;
    value: string;
  };
  startDate?: Date;
  endDate?: Date;
}

export interface ContentfulColorData {
  id: string;
  name: string;
  value: string;
}

export interface HomePageContentfulData {
  __typename?: string;
  heroHeading: string;
  heroSubheading: string | null;
  heroButtonText: string;
  mobileHeroImage: ContentfulImageData | null;
  mobileHeroColorOverlay: ContentfulColorData;
  mobileHeroOverlayColorOpacity: number;
  desktopHeroImage: ContentfulImageData;
  desktopHeroColorOverlay: ContentfulColorData;
  desktopHeroOverlayColorOpacity: number;
  emailListFormHeading: string;
  emailListFormSubheading: string | null;
  addColorOverlayDesktop: boolean | null;
  addColorOverlayMobile: boolean | null;
  instagramHeading: string | null;
  newListingsHeading: string | null;
}

export interface RichTextContentfulData {
  json: Document;
}

export interface ContentfulFaqQuestionData {
  question?: string;
  answer: RichTextContentfulData;
  __typename: string;
}

export interface ImageInTextContentfulData {
  image: ContentfulImageData;
  imageWidth: string;
  caption?: string;
  sys?: {
    id: string;
  };
}

export interface ContentfulListCollectionData {
  __typename: string;
  listCollectionCollection: {
    items: (ContentfulImageData | ContentfulAnnouncementData | ContentfulTextData | ContentfulFaqQuestionData)[];
  };
}

export enum CONTENT_TYPES {
  ASSET = 'Asset',
  TEXT = 'Text',
  ANNOUNCEMENT_BAR = 'AnnouncementBar',
  IMAGE = 'Image',
  LIST = 'List',
  PAGE = 'Page',
  FAQ_QUESTION = 'FaqQuestion'
}

export interface PageContentfulData<T extends any> {
  pageTitle: string;
  pageDescription: string;
  keywords: string[];
  pageDataCollection: { items: T[] };
}

export interface GlobalContentfulData<T extends any> {
  globalDataCollection: { items: T[] };
}

export type GlobalAnnouncementBar = Omit<
  ContentfulAnnouncementData,
  'showAnnouncement' | 'bannerTextColor' | 'bannerBackgroundColor'
>;

export type MainGlobalContentfulData = GlobalContentfulData<ContentfulTextData | GlobalAnnouncementBar>;

// Fragments
export enum CONTENTFUL_FRAGMENT_NAMES {
  IMAGE = 'contentfulImageFragment',
  IMAGE_ASSET = 'contentfulImageAssetFragment',
  TEXT = 'contentfulTextFragment',
  ANNOUNCEMENT = 'contentfulAnnouncementFragment',
  FAQ_QUESTION = 'contentfulFaqQuestionFragment',
  LIST = 'contentfulListFragment'
}

export const CONTENTFUL_FRAGMENTS = {
  IMAGE_ASSET: `
  fragment ${CONTENTFUL_FRAGMENT_NAMES.IMAGE_ASSET} on ${CONTENT_TYPES.ASSET} {
    title
    description
    url(transform: {format: WEBP})
    width
    height
  }
  `,
  IMAGE: `
  fragment ${CONTENTFUL_FRAGMENT_NAMES.IMAGE} on ${CONTENT_TYPES.IMAGE} {
    __typename
    image {
      ...${CONTENTFUL_FRAGMENT_NAMES.IMAGE_ASSET}
    }
  }
  `,
  TEXT: `
  fragment ${CONTENTFUL_FRAGMENT_NAMES.TEXT} on ${CONTENT_TYPES.TEXT} {
    __typename
    name
    text
  }
  `,
  ANNOUNCEMENT: `
  fragment ${CONTENTFUL_FRAGMENT_NAMES.ANNOUNCEMENT} on ${CONTENT_TYPES.ANNOUNCEMENT_BAR} {
    __typename
    name
    announcement
    showAnnouncement
    bannerBackgroundColor
    bannerTextColor
    startDate
    endDate
  }
  `,
  FAQ_QUESTION: `
  fragment ${CONTENTFUL_FRAGMENT_NAMES.FAQ_QUESTION} on ${CONTENT_TYPES.FAQ_QUESTION} {
    __typename
    question
    answer {
      json
    }
  }
  `,
  LIST: `
  fragment ${CONTENTFUL_FRAGMENT_NAMES.LIST} on ${CONTENT_TYPES.LIST} {
    __typename
    listCollectionCollection(limit: 30) {
      items {
        __typename
        ... on  ${CONTENT_TYPES.TEXT}  {
          ...${CONTENTFUL_FRAGMENT_NAMES.TEXT}
        }
        ... on  ${CONTENT_TYPES.IMAGE}  {
          ...${CONTENTFUL_FRAGMENT_NAMES.IMAGE}
        }
        ... on  ${CONTENT_TYPES.ANNOUNCEMENT_BAR}  {
          ...${CONTENTFUL_FRAGMENT_NAMES.ANNOUNCEMENT}
        }
        ... on ${CONTENT_TYPES.FAQ_QUESTION} {
          ...${CONTENTFUL_FRAGMENT_NAMES.FAQ_QUESTION}
        }
      }
    }
  }`
};

// End of fragments

export const getPageAnnouncement = (pageCollection?: { items: UnknownDict[] }) => {
  const announcementBar = pageCollection?.items?.find(({ __typename }) => __typename.includes('AnnouncementBar')) as
    | ContentfulAnnouncementData
    | undefined;

  const startDateHasStarted = announcementBar?.startDate ? dateHasPassed(announcementBar?.startDate) : true;
  const startDateHasEnded = announcementBar?.endDate ? dateHasPassed(announcementBar?.endDate) : false;

  const showAnnouncement =
    announcementBar?.showAnnouncement &&
    announcementBar?.announcement?.length > 0 &&
    startDateHasStarted &&
    !startDateHasEnded;

  return { ...announcementBar, startDateHasStarted, startDateHasEnded, showAnnouncement };
};

export const contentfulConsistentHeaders = {
  Authorization: `Bearer ${process.env.CONTENTFUL_PERSONAL_ACCESS_TOKEN}`
};

export enum ContentfulEntryIds {
  instagramToken = '2neIOpenE9eRRPkXbPm4NK',
  global = '64wbzFQO4SUOerI05nGuY5',
  homepage = '5xh3qO0DuQCkGhTW8FI11Q',
  plpPage = 'iu4DaBxj5xb3FWpCqGw0U',
  pdpPage = '2Vbv58VrIpm8PG3IRZFslP',
  contactPage = 'H9hxLpLMlZxKvzwSR1fyg',
  cartPage = '4hmX6PY7H3DYjQb8JsxCSw',
  faqPage = '3GMuZ79lTcojgqIYjiOapc',
  aboutPage = '5RHtWb4lzqQr6QdMcOAulY'
}

export const contentfulKeys = {
  spaceId: process.env.CONTENTFUL_SPACE_ID ?? '',
  apiKey: process.env.CONTENTFUL_DELIVERY_ACCESS_TOKEN ?? '',
  previewApiKey: process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN ?? ''
};

export const contentfulQueries = {
  global: `
  ${CONTENTFUL_FRAGMENTS.TEXT}
  query {
    global(id: "${ContentfulEntryIds.global}") {
      globalDataCollection {
        items {
          ... on Text {
            ...${CONTENTFUL_FRAGMENT_NAMES.TEXT}
          }
          ... on GlobalAnnouncementBar {
            __typename
            name
            announcement
            startDate
            endDate
          }
        }
      }
    } 
  }`,
  instagramToken: `query {
    instagramAccessToken(id: "${ContentfulEntryIds.instagramToken}") {
      expirationDate
      name
      accessToken
    }
  }`,
  homePage: `
  ${CONTENTFUL_FRAGMENTS.IMAGE_ASSET}
  ${CONTENTFUL_FRAGMENTS.ANNOUNCEMENT}
  query {
    page(id: "${ContentfulEntryIds.homepage}") {
      pageTitle
      pageDescription
      keywords
      pageDataCollection {
        items {
          ... on HomePage {
            __typename
            name
            heroHeading
            heroSubheading
            heroButtonText
            mobileHeroImage {
              ...${CONTENTFUL_FRAGMENT_NAMES.IMAGE_ASSET}
            }
            addColorOverlayMobile
            mobileHeroColorOverlay
            mobileHeroOverlayColorOpacity
            desktopHeroImage {
              ...${CONTENTFUL_FRAGMENT_NAMES.IMAGE_ASSET}
            }
            addColorOverlayDesktop
            desktopHeroColorOverlay
            desktopHeroOverlayColorOpacity
            emailListFormHeading
            emailListFormSubheading
            instagramHeading
            newListingsHeading
          }
          ... on ${CONTENT_TYPES.ANNOUNCEMENT_BAR} {
            ...${CONTENTFUL_FRAGMENT_NAMES.ANNOUNCEMENT}
          }
        }    
      }
    } 
  }`,
  contactPage: `
  ${CONTENTFUL_FRAGMENTS.IMAGE}
  ${CONTENTFUL_FRAGMENTS.IMAGE_ASSET}
  ${CONTENTFUL_FRAGMENTS.TEXT}
  ${CONTENTFUL_FRAGMENTS.ANNOUNCEMENT}
  query {
    page(id: "${ContentfulEntryIds.contactPage}") {
      pageTitle
      pageDescription
      keywords
      pageDataCollection {
        items {
          ... on ${CONTENT_TYPES.IMAGE} {      
            ...${CONTENTFUL_FRAGMENT_NAMES.IMAGE}
          }
          ... on ${CONTENT_TYPES.TEXT} {
            ...${CONTENTFUL_FRAGMENT_NAMES.TEXT}
          }
          ... on ${CONTENT_TYPES.ANNOUNCEMENT_BAR} {
            ...${CONTENTFUL_FRAGMENT_NAMES.ANNOUNCEMENT}
          }
        }
      }
    } 
  }`,
  plpPage: `
  ${CONTENTFUL_FRAGMENTS.TEXT}
  ${CONTENTFUL_FRAGMENTS.ANNOUNCEMENT}
  query {
    page(id: "${ContentfulEntryIds.plpPage}") {
      pageTitle
      pageDescription
      keywords
      pageDataCollection {
        items {
          ... on ${CONTENT_TYPES.TEXT} {
            ...${CONTENTFUL_FRAGMENT_NAMES.TEXT}
          }
          ... on ${CONTENT_TYPES.ANNOUNCEMENT_BAR} {
            ...${CONTENTFUL_FRAGMENT_NAMES.ANNOUNCEMENT}
          }
        }
      }
    } 
  }`,
  pdpPage: `
  ${CONTENTFUL_FRAGMENTS.TEXT}
  ${CONTENTFUL_FRAGMENTS.ANNOUNCEMENT}
  query {
    page(id: "${ContentfulEntryIds.pdpPage}") {
      pageTitle
      pageDescription
      keywords
      pageDataCollection {
        items {
          ... on ${CONTENT_TYPES.TEXT} {
            ...${CONTENTFUL_FRAGMENT_NAMES.TEXT}
          }
          ... on ${CONTENT_TYPES.ANNOUNCEMENT_BAR} {
            ...${CONTENTFUL_FRAGMENT_NAMES.ANNOUNCEMENT}
          }
        }
      }
    } 
  }`,
  cartPage: `
  ${CONTENTFUL_FRAGMENTS.ANNOUNCEMENT}
  query {
    page(id: "${ContentfulEntryIds.cartPage}") {
      pageTitle
      pageDescription
      keywords
      pageDataCollection {
        items {
          ... on ${CONTENT_TYPES.ANNOUNCEMENT_BAR} {
            ...${CONTENTFUL_FRAGMENT_NAMES.ANNOUNCEMENT}
          }
        }
      }
    } 
  }`,
  faqPage: `
  ${CONTENTFUL_FRAGMENTS.ANNOUNCEMENT}
  ${CONTENTFUL_FRAGMENTS.LIST}
  ${CONTENTFUL_FRAGMENTS.TEXT}
  ${CONTENTFUL_FRAGMENTS.IMAGE}
  ${CONTENTFUL_FRAGMENTS.IMAGE_ASSET}
  ${CONTENTFUL_FRAGMENTS.FAQ_QUESTION}
  query {
    page(id: "${ContentfulEntryIds.faqPage}") {
      pageTitle
      pageDescription
      keywords
      pageDataCollection(limit: 10) {
        items {
          ... on ${CONTENT_TYPES.ANNOUNCEMENT_BAR} {
            ...${CONTENTFUL_FRAGMENT_NAMES.ANNOUNCEMENT}
          }
          ... on ${CONTENT_TYPES.LIST} {
            ...${CONTENTFUL_FRAGMENT_NAMES.LIST}
          }
        }
      }
    } 
  }`,
  aboutPage: `
  ${CONTENTFUL_FRAGMENTS.IMAGE_ASSET}
  ${CONTENTFUL_FRAGMENTS.IMAGE}
  query {
    page(id: "${ContentfulEntryIds.aboutPage}") {
      pageTitle
      pageDescription
      keywords
      pageDataCollection {
        items {
          ... on ${CONTENT_TYPES.IMAGE} {      
            ...${CONTENTFUL_FRAGMENT_NAMES.IMAGE}
          }
        }
      }
    } 
  }`
};

export type ContentfulQueryOptions = keyof typeof contentfulQueries;

export const fetchContentfulContentData = async (
  data: {
    query: ContentfulQueryOptions;
    variables?: UnknownDict;
  },
  preview = false
) => {
  let errors;

  try {
    const response = await fetch(
      `https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE_ID}`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${preview ? contentfulKeys.previewApiKey : contentfulKeys.apiKey}`
        },
        body: JSON.stringify({
          // Trimming whitespace and removing newline characters to help keep query size from getting too large
          query: contentfulQueries[data.query].replace(/\r?\n|\r/g, '').trim(),
          variables: data?.variables
        })
      }
    );

    const resJSON = await response?.json();

    if (resJSON?.errors) {
      errors = resJSON?.errors;
      return {
        data,
        errors: resJSON?.errors
      };
    }

    return resJSON?.data;
  } catch (error) {
    apiError({
      errorInText: 'FETCHING CONTENTFUL DATA FROM GRAPHQL API',
      error
    });
  }

  if (errors) return errors;
};
