import { NextApiRequest, NextApiResponse } from 'next';
import { apiError, BASE_URL } from '../../../pages/api/utils';
import {
  CHECKOUT_ERROR_FRAGMENT,
  CHECKOUT_FRAGMENT,
  ShopifyFragmentsNames,
  shopifyStoreFront,
  transformCheckout
} from '../../../pages/api/utils/shopifyApi';
import { CheckoutRes } from '../../../types/store';

const updateShippingMethodQuery = `
${CHECKOUT_FRAGMENT}
${CHECKOUT_ERROR_FRAGMENT}
mutation checkoutShippingLineUpdate($checkoutId: ID!, $shippingRateHandle: String!) {
  checkoutShippingLineUpdate(checkoutId: $checkoutId, shippingRateHandle: $shippingRateHandle) {
    checkout {
      ...${ShopifyFragmentsNames.CHECKOUT_FRAGMENT}
    }
    checkoutUserErrors {
      ...${ShopifyFragmentsNames.CHECKOUT_ERROR_FRAGMENT}
    }  
  }
}
`;

const shopifyUpdateShippingMethod = async (req: NextApiRequest, res: NextApiResponse) => {
  const { body } = req;

  try {
    let response = await shopifyStoreFront(updateShippingMethodQuery, JSON.parse(body));

    res.status(200);
    res.json(transformCheckout(response?.checkoutShippingLineUpdate));
  } catch (error) {
    apiError({
      errorInText: 'update shipping method - checkout',
      error,
      res,
      resJsonKey: 'checkoutUserErrors'
    });
  }
};

export interface UpdateShippingMethodBody {
  checkoutId: string;
  shippingRateHandle: string;
}

export const updateShippingMethod = async (body: UpdateShippingMethodBody): Promise<CheckoutRes | undefined> => {
  try {
    const response = await fetch(`${BASE_URL}/api/shopify/checkout/update-shipping-method`, {
      method: 'POST',
      body: JSON.stringify(body)
    });

    const resJson = await response?.json();

    return resJson;
  } catch (e) {
    console.error('updateShippingMethod failed', e);
  }
};

export default shopifyUpdateShippingMethod;
