import { useState } from 'react';
import { UnknownDict } from '../../global';
import useAnalytics from '../../hooks/useAnalytics';
import { addEmailSubscription } from '../../pages/api/email-subscription/add-subscriber';
import { getEmailSubscriptionLists } from '../../pages/api/email-subscription/get-subscription-lists';
import { apiError } from '../../pages/api/utils';
import { KnownSubscriptionListNames } from '../../utils/const';
import InlineForm, { InlineFormOnSubmitTools, InlineFormProps } from '../Design/Starters/InlineForm';

interface MailingListFormProps extends Omit<InlineFormProps, 'onSubmit' | 'buttonChildren' | 'fieldParams'> {
  flipErrorColors?: boolean;
}

const MailingListForm = ({ label, ...props }: MailingListFormProps) => {
  const [success, setSuccess] = useState(false);
  const { trackCustomEvent } = useAnalytics();

  const onSubmit = async (data: UnknownDict, tools?: InlineFormOnSubmitTools) => {
    const { setError, reset } = tools ?? {};

    try {
      const emailSubScriptionLists = await getEmailSubscriptionLists();

      const subscriberListId = emailSubScriptionLists?.lists?.find(
        ({ name }) => name === KnownSubscriptionListNames.aeribellaEmailSubscriber
      )?.id;

      if (!subscriberListId) {
        setError?.('email', { message: 'Something went wrong' });
        throw Error('No subscriberListId from SendInBlue');
      }

      if (data?.email && subscriberListId) {
        const subscribed = await addEmailSubscription({ email: data?.email, listIds: [subscriberListId] });

        if ((subscribed as any)?.code) {
          setError?.('email', { message: (subscribed as any)?.message });
          throw Error((subscribed as any)?.message);
        }

        if (subscribed?.id) {
          console.log('mailing list sign up');

          trackCustomEvent(
            'Mailing List',
            JSON.stringify({
              subscriberListId,
              email: data?.email
            })
          );

          setSuccess(true);

          setTimeout(() => {
            reset?.({ email: '' });
            setSuccess(false);
          }, 3000);
        }

        return;
      }

      throw Error('email or subscriberListId missing in MailingListForm');
    } catch (e) {
      apiError({ errorInText: 'mailer form', error: e });
    }
  };

  return (
    <InlineForm
      {...props}
      success={success}
      onSubmit={onSubmit}
      label={label ? label : 'Get updates and special offers:'}
      buttonChildren="Sign Up"
      buttonColor="tertiary"
      fieldParams={{
        name: 'email',
        rules: {
          pattern: {
            value:
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'Invalid email address'
          },
          required: 'Please enter your email'
        },
        placeholder: 'example@example.com',
        autocomplete: 'email'
      }}
    />
  );
};

export default MailingListForm;
