import React, { forwardRef } from 'react';
import { Box, Button, Flex, Text } from '../..';
import { PolymorphicComponentProps } from './Box';
import { FlexProps } from './Flex';
import { radioRecipe, RadioVariants } from './radio.css';
import { TextProps } from './Text';

type StyleProps = {} & RadioVariants;

type UVariant<T = StyleProps> = T extends RadioVariants ? Omit<T, 'variant' | 'focusVisible'> : never;

type RadioOwnProps = UVariant & {
  disabled?: boolean;
  variant?: StyleProps['variant'];
  label: React.ReactNode;
  labelProps?: TextProps<'p'>;
  containerProps?: FlexProps<'div'>;
  isRadioButton?: boolean;
};

export type RadioProps<E extends React.ElementType> = PolymorphicComponentProps<E, RadioOwnProps>;

const defaultElement = 'input';

export const Radio: <E extends React.ElementType = typeof defaultElement>(
  props: RadioProps<E>
) => React.ReactElement | null = forwardRef(
  <E extends React.ElementType = typeof defaultElement>(
    {
      variant,
      size,
      focusVisible = false,
      label,
      hasError,
      labelProps,
      containerProps,
      isRadioButton,
      checked,
      disabled,
      ...props
    }: RadioProps<E>,
    ref: typeof props.ref
  ) => {
    const checkboxClassName = radioRecipe({ variant, size, focusVisible, error: hasError });

    if (isRadioButton) {
      return (
        <Flex
          as="label"
          align="center"
          gap={isRadioButton ? '0px' : '12px'}
          style={{ cursor: 'pointer', pointerEvents: disabled ? 'none' : 'auto' }}
          width="fitContent"
          position="relative"
          {...containerProps}>
          <Box
            as="input"
            type="radio"
            className={checkboxClassName}
            position="absolute"
            zIndex="docked"
            disabled={disabled}
            {...props}
            checked={checked}
            ref={ref}
            style={{ visibility: 'hidden', pointerEvents: disabled ? 'none' : 'auto' }}
          />
          <Button
            zIndex="base"
            disabled={disabled}
            onClick={(e: any) => {
              e?.preventDefault();
              e.target.value = props?.value;

              props?.onChange?.(e);
            }}
            {...{ variant: checked ? 'primary' : 'gray' }}
            style={{ pointerEvents: disabled ? 'none' : 'auto' }}
            flex="1"
            width="fitContent">
            <Text as="span" size="sm" {...{ color: 'inherit', ...labelProps }} flex="1">
              {label}
            </Text>
          </Button>
        </Flex>
      );
    }

    return (
      <Flex as="label" align="center" gap="12px" style={{ cursor: 'pointer' }} {...containerProps}>
        <Box as="input" type="radio" className={checkboxClassName} {...props} checked={checked} ref={ref} />
        <Text as="span" size="sm" {...{ color: 'primary', ...labelProps }} flex="1">
          {label}
        </Text>
      </Flex>
    );
  }
);
