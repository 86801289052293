import { NextApiRequest, NextApiResponse } from 'next';
import { apiError, BASE_URL } from '../../../pages/api/utils';
import {
  CHECKOUT_ERROR_FRAGMENT,
  CHECKOUT_FRAGMENT,
  ShopifyFragmentsNames,
  shopifyStoreFront,
  transformCheckout
} from '../../../pages/api/utils/shopifyApi';
import { CheckoutRes } from '../../../types/store';

const addGiftCardQuery = `
${CHECKOUT_FRAGMENT}
${CHECKOUT_ERROR_FRAGMENT}
mutation checkoutGiftCardsAppend($checkoutId: ID!, $giftCardCodes: [String!]!) {
  checkoutGiftCardsAppend(checkoutId: $checkoutId, giftCardCodes: $giftCardCodes) {
    checkout {
      ...${ShopifyFragmentsNames.CHECKOUT_FRAGMENT}
    }
    checkoutUserErrors {
      ...${ShopifyFragmentsNames.CHECKOUT_ERROR_FRAGMENT}
    }  
  }
}
`;

const shopifyAddGiftCard = async (req: NextApiRequest, res: NextApiResponse) => {
  const { body } = req;

  try {
    let response = await shopifyStoreFront(addGiftCardQuery, JSON.parse(body));

    res.status(200);
    res.json(transformCheckout(response?.checkoutGiftCardsAppend));
  } catch (error) {
    apiError({ errorInText: 'add gift card - checkout', error, res, resJsonKey: 'checkoutUserErrors' });
  }
};

export interface AddGiftCardBody {
  checkoutId: string;
  giftCardCodes: string[];
}

export const addGiftCard = async (body: AddGiftCardBody): Promise<CheckoutRes | undefined> => {
  try {
    const response = await fetch(`${BASE_URL}/api/shopify/checkout/add-gift-card`, {
      method: 'POST',
      body: JSON.stringify(body)
    });

    const resJson = await response?.json();

    return resJson;
  } catch (e) {
    console.error('addGiftCard failed', e);
  }
};

export default shopifyAddGiftCard;
