import * as React from 'react';
import { Sprinkles } from '../../sprinkles.css';
import { Box, PolymorphicComponentProps } from './Box';

type FlexOwnProps = {
  direction?: Sprinkles['flexDirection'];
  align?: Sprinkles['alignItems'];
  justify?: Sprinkles['justifyContent'];
};

export type FlexProps<E extends React.ElementType> = PolymorphicComponentProps<E, FlexOwnProps>;

const defaultElement = 'div';

export const Flex: <E extends React.ElementType = typeof defaultElement>(
  props: FlexProps<E>
) => React.ReactElement | null = React.forwardRef(
  <E extends React.ElementType = typeof defaultElement>(
    { direction = 'row', align = 'flex-start', justify = 'flex-start', children, ...restProps }: FlexProps<E>,
    ref: typeof restProps.ref
  ) => {
    return (
      <Box
        as={defaultElement}
        display="flex"
        flexDirection={direction}
        alignItems={align}
        justifyContent={justify}
        ref={ref}
        {...restProps}>
        {children}
      </Box>
    );
  }
);
