import React, { forwardRef, useRef } from 'react';
import { ChevronDown } from '../../Icons';
import { Box, BoxProps, PolymorphicComponentProps } from './Box';
import { Flex, FlexProps } from './Flex';
import { selectRecipe, SelectVariants } from './select.css';

type StyleProps = {} & SelectVariants;

type UVariant<T = StyleProps> = T extends SelectVariants ? Omit<T, 'variant' | 'size'> : never;

type SelectOwnProps = UVariant & {
  disabled?: boolean;
  variant?: 'unstyled' | StyleProps['variant'];
  hasError?: boolean;
  children?: React.ReactNode;
  icon?: React.ReactNode | JSX.Element;
  iconProps?: BoxProps<'div'>;
  chevronSize?: string;
  containerProps?: Omit<FlexProps<'div'>, 'align' | 'width' | 'position' | 'flex'>;
};

export type SelectProps<E extends React.ElementType> = Omit<PolymorphicComponentProps<E, SelectOwnProps>, 'size'> & {
  size?: StyleProps['size'];
};

const defaultElement = 'select';

export const Select: <E extends React.ElementType = typeof defaultElement>(
  props: SelectProps<E>
) => React.ReactElement | null = forwardRef(
  <E extends React.ElementType = typeof defaultElement>(
    {
      variant,
      size,
      hasError,
      children,
      icon,
      iconProps,
      chevronSize,
      containerProps,
      className,
      ...props
    }: SelectProps<E>,
    ref: typeof props.ref
  ) => {
    const selectRef = useRef<HTMLSelectElement | null>(null);
    const selectClassName = selectRecipe({ variant, size, error: hasError });

    return (
      <Flex
        align="center"
        borderRadius="base"
        width="full"
        position="relative"
        flex="1"
        background={variant === 'filled' ? 'gray' : 'white'}
        {...containerProps}>
        <Box
          as="select"
          ref={(node: any) => {
            selectRef.current = node;
            if (ref) ref.current = node;
          }}
          className={[selectClassName, className].join(' ')}
          zIndex="docked"
          paddingRight="12px"
          textTransform="capitalize"
          {...props}>
          {children}
        </Box>

        <Box
          width="fitContent"
          minWidth="20px"
          display="flex"
          zIndex="base"
          alignItems="center"
          position="absolute"
          top="0px"
          right="0px"
          style={{
            height: '100%',
            right: chevronSize ? `-${+chevronSize.replace(/px/g, '')}px` : '8px',
            ...iconProps?.style
          }}
          {...iconProps}>
          {icon ?? <ChevronDown size={chevronSize ?? '20px'} />}
        </Box>
      </Flex>
    );
  }
);
