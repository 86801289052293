import { NextApiRequest, NextApiResponse } from 'next';
import { CartData } from '../../../../types/store';
import { BASE_URL, apiError } from '../../utils';
import { CART_FRAGMENT, ShopifyFragmentsNames, cleanGraphQLResponse, shopifyStoreFront } from '../../utils/shopifyApi';

const CartDiscountCodeUpdateQuery = `
${CART_FRAGMENT}
mutation CartDiscountCodeUpdate($cartId: ID!, $discountCodes: [String!]) {
  cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
    cart {
     ...${ShopifyFragmentsNames.CART_FRAGMENT}
    }
  }
}
`;

const shopifyCartDiscountCodeUpdate = async (req: NextApiRequest, res: NextApiResponse) => {
  const { body } = req;

  try {
    let response = await shopifyStoreFront(CartDiscountCodeUpdateQuery, JSON.parse(body));
    response = response ? cleanGraphQLResponse(response?.cartDiscountCodesUpdate?.cart) : null;

    res.status(200);
    res.json(response);
  } catch (error) {
    apiError({ errorInText: 'cart discount code update', error, res, resJsonKey: 'userErrors' });
  }
};

export interface UpdateCartDiscountCodeBody {
  cartId: string;
  discountCodes: string[];
}

export const updateCartDiscountCode = async (body: UpdateCartDiscountCodeBody): Promise<CartData | undefined> => {
  try {
    const response = await fetch(`${BASE_URL}/api/shopify/cart/cart-discount-code-update`, {
      method: 'POST',
      body: JSON.stringify(body)
    });

    const resJson = await response?.json();

    return resJson;
  } catch (e: any) {
    console.error('updateCartDiscountCode failed', e);
    if (e) return e;
  }
};

export default shopifyCartDiscountCodeUpdate;
