import { NextApiResponse } from 'next';

export const BASE_URL =
  typeof window === 'undefined'
    ? `${process.env.NODE_ENV === 'development' ? 'http' : 'https'}://${process.env.NEXT_PUBLIC_VERCEL_URL}`
    : '';

export interface ApiErrorProps {
  errorInText: string;
  resJsonKey?: string;
  error: any;
  res?: NextApiResponse;
  errorResponseOverRide?: string;
  isEdge?: boolean;
}

export const apiError = ({ errorInText, resJsonKey, error, res, errorResponseOverRide, isEdge }: ApiErrorProps) => {
  const errorText = `${errorInText.toUpperCase()}`;
  console.error(`\x1b[33mERROR IN ${errorText}\x1b[0m`);
  console.error(error);

  const body = resJsonKey
    ? {
        [resJsonKey]: [
          {
            code: errorResponseOverRide ? 'custom' : 'default',
            field: null,
            message: errorResponseOverRide ?? 'Something went wrong. Please try again later.'
          }
        ]
      }
    : {
        code: 'default',
        field: null,
        message: errorResponseOverRide ?? 'Something went wrong. Please try again later.'
      };

  if (isEdge) {
    return new Response(JSON.stringify(body), {
      status: 500,
      headers: {
        'content-type': 'application/json'
      }
    });
  } else if (res) {
    res.status(500);
    res.json(body);

    throw error;
  }
};
