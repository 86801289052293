//@ts-nocheck
import React, { forwardRef } from 'react';
import { Box, Flex, Text } from '../..';
import { PolymorphicComponentProps } from './Box';
import { CheckBoxVariants, checkboxRecipe } from './checkbox.css';

type StyleProps = {} & CheckBoxVariants;

type UVariant<T = StyleProps> = T extends CheckBoxVariants ? Omit<T, 'variant'> : never;

type CheckBoxOwnProps = UVariant & {
  disabled?: boolean;
  variant?: StyleProps['variant'];
  label: React.ReactNode;
};

export type CheckboxProps = PolymorphicComponentProps<'input', CheckBoxOwnProps>;

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ variant, size, focusVisible = false, label, hasError, disabled, ...props }, ref) => {
    const checkboxClassName = checkboxRecipe({ variant, size, focusVisible, error: hasError });

    return (
      <Flex
        as="label"
        align="center"
        color="black"
        textTransform="capitalize"
        gap="12px"
        opacity={disabled ? '0.5' : '1'}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
        <Box as="input" type="checkbox" tabIndex={0} className={checkboxClassName} {...props} ref={ref} />
        {typeof label === 'string' ? (
          <Text as="span" variant="label" lineHeight="none" flex="1">
            {label}
          </Text>
        ) : (
          label
        )}
      </Flex>
    );
  }
);
