// import type { CommerceAPI } from 'commerce-api';
import { action, autorun, makeAutoObservable, observable } from 'mobx';
import { UnknownDict } from '../global';
import { GlobalAnnouncementBar } from '../pages/api/utils/contentful';
import { StoreModel } from '../types/store';
import { dateHasPassed } from '../utils';
import { CartHydration, CartStore } from './cartStore';
import { CheckoutHydration, CheckoutStore } from './checkoutStore';
import { ProductHydration, ProductStore } from './productStore';

export interface RootStoreHydration {
  productStore: ProductHydration;
  cartStore: CartHydration;
  checkoutStore: CheckoutHydration;
  global: {
    announcementBar?: GlobalAnnouncementBar | null;
    globalError?: string | null;
    showConsentBanner?: boolean;
    isRestricted?: boolean;
    allowCheckout?: boolean;
  };
}

export const originIsNotProd =
  process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production' && process.env.NODE_ENV !== 'development';

const inConstruction = !dateHasPassed({ day: 8, year: 2023, month: 'may' }) || !!originIsNotProd;

export class RootStore implements StoreModel {
  productStore: ProductStore;
  cartStore: CartStore;
  checkoutStore: CheckoutStore;
  announcementBar: RootStoreHydration['global']['announcementBar'];
  globalError: RootStoreHydration['global']['globalError'];
  showConsentBanner: RootStoreHydration['global']['showConsentBanner'];
  isRestricted: RootStoreHydration['global']['isRestricted'];
  allowCheckout: RootStoreHydration['global']['allowCheckout'];

  constructor(initialState: UnknownDict) {
    this.productStore = new ProductStore(this, initialState?.productStore);
    this.cartStore = new CartStore(this, initialState?.cartStore);
    this.checkoutStore = new CheckoutStore(this, initialState?.checkoutStore);
    this.announcementBar = undefined;
    this.globalError = undefined;
    this.showConsentBanner = false;
    this.isRestricted = inConstruction && process.env.NODE_ENV !== 'development';
    this.allowCheckout = !inConstruction || process.env.NODE_ENV === 'development';

    autorun(async () => {
      if (!this.cartStore?.cart) {
        await this.cartStore?.createCart();
        console.log('Created Cart');
      }

      const localStorageClone = typeof window !== 'undefined' ? localStorage : undefined;
      const origin = typeof window !== 'undefined' ? window.origin : undefined;
      const urlSearchParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : undefined;
      const params = urlSearchParams ? Object.fromEntries(urlSearchParams?.entries()) : undefined;

      // Bypass isRestricted
      const bypassRestrictedPage = params?.bypass || false;
      const bypass = 'bypass';
      const localStorageItem = localStorageClone?.getItem(bypass);
      const getRestrictionBypass = (localStorageItem && JSON.parse(localStorageItem)) || bypassRestrictedPage;

      if (getRestrictionBypass) {
        console.log('Restriction Bypass', getRestrictionBypass);
        this.setIsRestricted(!getRestrictionBypass);
        if (bypassRestrictedPage) localStorageClone?.setItem(bypass, JSON.stringify(bypassRestrictedPage));
      }

      // Allow Checkout
      const bypassRestrictedCheckoutButton = (params?.allowCheckout && JSON.parse(params?.allowCheckout)) || false;
      const allowCheckout = 'allowCheckout';
      const localStorageAllowCheckoutItem = localStorageClone?.getItem(allowCheckout);
      const getRestrictedCheckoutButtonBypass =
        (localStorageAllowCheckoutItem && JSON.parse(localStorageAllowCheckoutItem)) || bypassRestrictedCheckoutButton;

      if (getRestrictedCheckoutButtonBypass) {
        console.log('Restricted Checkout Bypass', getRestrictedCheckoutButtonBypass);
        this.setAllowCheckout(getRestrictedCheckoutButtonBypass);
        if (bypassRestrictedCheckoutButton)
          localStorageClone?.setItem(allowCheckout, JSON.stringify(bypassRestrictedCheckoutButton));
      }

      // Restrict all urls that are not prod or dev environment
      if (!origin?.includes('aeribella.com') && process.env.NODE_ENV !== 'development') {
        this.setIsRestricted(!getRestrictionBypass);
        this.setAllowCheckout(getRestrictedCheckoutButtonBypass);
        return;
      }
    });

    makeAutoObservable(this, {
      hydrate: action,
      announcementBar: observable,
      globalError: observable,
      showConsentBanner: observable,
      isRestricted: observable,
      allowCheckout: observable,
      setGlobalError: action.bound,
      setShowConsentBanner: action.bound,
      setIsRestricted: action.bound,
      setAllowCheckout: action.bound,
      updateAnnouncementBar: action.bound
    });
  }

  get asJson() {
    return {
      announcementBar: this.announcementBar,
      productStore: this.productStore.asJson,
      cartStore: this.cartStore.asJson,
      checkoutStore: this.checkoutStore.asJson,
      globalError: this.globalError,
      isRestricted: this.isRestricted,
      showConsentBanner: this.showConsentBanner,
      allowCheckout: this.allowCheckout
    };
  }

  setGlobalError(errorMessage: string | undefined) {
    this.globalError = errorMessage;
    throw new Error(`Global Error: ${errorMessage}`);
  }

  setShowConsentBanner(show: boolean) {
    this.showConsentBanner = show;
  }

  setIsRestricted(show: boolean) {
    this.isRestricted = show;
  }

  setAllowCheckout(allow: boolean) {
    this.allowCheckout = allow;
  }

  updateAnnouncementBar(info: RootStoreHydration['global']['announcementBar']) {
    this.announcementBar = info;
  }

  hydrate(data: RootStoreHydration) {
    if (data?.global) {
      this.announcementBar = data.global.announcementBar;
    }
    if (data?.productStore) {
      this.productStore.hydrate(data.productStore);
    }
    if (data?.cartStore) {
      this.cartStore.hydrate(data.cartStore);
    }
    if (data?.checkoutStore) {
      this.checkoutStore.hydrate(data.checkoutStore);
    }
  }

  getState() {
    return this.asJson;
  }
}
