import * as React from 'react';
import { Sprinkles } from '../../sprinkles.css';
import { Box, PolymorphicComponentProps } from './Box';
import { stackRecipe, StackVariants } from './stack.css';

type StackOwnProps = StackVariants & {
  spacing?: Sprinkles['gap'];
  direction?: Sprinkles['flexDirection'];
  align?: Sprinkles['alignItems'];
  justify?: Sprinkles['justifyContent'];
};

type StackProps<E extends React.ElementType> = PolymorphicComponentProps<E, StackOwnProps>;

const defaultElement = 'div';

export const Stack: <E extends React.ElementType = typeof defaultElement>(
  props: StackProps<E>
) => React.ReactElement | null = React.forwardRef(
  <E extends React.ElementType = typeof defaultElement>(
    {
      variant,
      spacing = '8px',
      align = 'stretch',
      justify = 'flex-start',
      direction = 'column',
      className,
      children,
      ...restProps
    }: StackProps<E>,
    ref: typeof restProps.ref
  ) => {
    let _className = stackRecipe({ variant });

    return (
      <Box
        ref={ref}
        display="flex"
        flexDirection={direction}
        className={[_className, className].join(' ')}
        gap={spacing}
        alignItems={align}
        justifyContent={justify}
        {...restProps}>
        {children}
      </Box>
    );
  }
);
