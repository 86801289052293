import { NextApiRequest, NextApiResponse } from 'next';
import { apiError, BASE_URL } from '../../../pages/api/utils';
import {
  CHECKOUT_ERROR_FRAGMENT,
  CHECKOUT_FRAGMENT,
  ShopifyFragmentsNames,
  shopifyStoreFront,
  transformCheckout
} from '../../../pages/api/utils/shopifyApi';
import { CheckoutRes } from '../../../types/store';

const applyDiscountCodeQuery = `
${CHECKOUT_FRAGMENT}
${CHECKOUT_ERROR_FRAGMENT}
mutation checkoutDiscountCodeApplyV2($checkoutId: ID!, $discountCode: String!) {
  checkoutDiscountCodeApplyV2(checkoutId: $checkoutId, discountCode: $discountCode) {
    checkout {
      ...${ShopifyFragmentsNames.CHECKOUT_FRAGMENT}
    }
    checkoutUserErrors {
      ...${ShopifyFragmentsNames.CHECKOUT_ERROR_FRAGMENT}
    }  
  }
}
`;

// TODO: Finish RES when implementing
const shopifyApplyDiscountCode = async (req: NextApiRequest, res: NextApiResponse) => {
  const { checkoutId, discountCode } = req?.query;

  try {
    let response = await shopifyStoreFront(applyDiscountCodeQuery, {
      checkoutId,
      discountCode
    });

    res.status(200);
    res.json(transformCheckout(response?.checkoutDiscountCodeApplyV2));
  } catch (error) {
    apiError({ errorInText: 'apply discount - checkout', error, res, resJsonKey: 'checkoutUserErrors' });
  }
};

export const applyDiscountCode = async (checkoutId: string, discountCode: string): Promise<CheckoutRes | undefined> => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/shopify/checkout/apply-discount-code?checkoutId=${checkoutId}&discountCode=${discountCode}`
    );

    const resJson = await response?.json();

    return resJson;
  } catch (e) {
    console.error('applyDiscountCode failed', e);
  }
};

export default shopifyApplyDiscountCode;
