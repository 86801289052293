// import { Token as StripeToken } from '@stripe/stripe-js';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { AddGiftCardBody, addGiftCard } from '../unused/unused-apis/checkout/add-gift-card';
import { AddLineItemsBody, addLineItems } from '../unused/unused-apis/checkout/add-line-item';
import { applyDiscountCode } from '../unused/unused-apis/checkout/apply-discount-code';
import { CreateCheckoutBody, createCheckout } from '../unused/unused-apis/checkout/create-checkout';
import { getCheckoutById } from '../unused/unused-apis/checkout/get-checkout-by-id';
import { removeDiscountCode } from '../unused/unused-apis/checkout/remove-discount-code';
import { RemoveGiftCardBody, removeGiftCard } from '../unused/unused-apis/checkout/remove-gift-card';
import { RemoveLineItemBody, removeLineItem } from '../unused/unused-apis/checkout/remove-line-item';
import { UpdateAddressBody, updateAddress } from '../unused/unused-apis/checkout/update-address';
import { updateEmail } from '../unused/unused-apis/checkout/update-email';
import { UpdateShippingMethodBody, updateShippingMethod } from '../unused/unused-apis/checkout/update-shipping-method';
// import { useStripeIntent, UseStripeIntentBody } from '../pages/api/unused/payments/stripe-intent';
import { CheckoutData, CheckoutRes, StoreModel, UserErrorData } from '../types/store';
import { apiErrorMessage } from '../utils/const';
import { RootStore } from './rootStore';

export interface CheckoutHydration {
  loading?: boolean;
  checkout?: CheckoutData | null;
  checkoutUserErrors?: UserErrorData[];
  addressUnset?: boolean;
  // stripePaymentIntentData?: UnknownDict;
  // stripeCardTokenData?: StripeToken;
}

export class CheckoutStore implements StoreModel {
  checkout: CheckoutHydration['checkout'];
  checkoutUserErrors: CheckoutHydration['checkoutUserErrors'];
  loading: CheckoutHydration['loading'];
  rootStore: RootStore;
  // stripePaymentIntentData: CheckoutHydration['stripePaymentIntentData'];
  // stripeCardTokenData: CheckoutHydration['stripeCardTokenData'];

  constructor(
    rootStore: RootStore,
    initialState: CheckoutHydration = {
      checkout: undefined,
      loading: false
    }
  ) {
    this.checkout = initialState.checkout;
    this.checkoutUserErrors = initialState.checkoutUserErrors;
    this.loading = initialState.loading;
    this.rootStore = rootStore;
    // this.stripePaymentIntentData = initialState.stripePaymentIntentData;
    // this.stripeCardTokenData = initialState.stripeCardTokenData;

    makeObservable(this, {
      loading: observable,
      checkout: observable,
      // stripePaymentIntentData: observable,
      checkoutUserErrors: observable,
      // stripeCardTokenData: observable,
      createCheckout: flow.bound,
      getCheckoutById: flow.bound,
      addGiftCard: flow.bound,
      removeGiftCard: flow.bound,
      addLineItems: flow.bound,
      removeLineItem: flow.bound,
      applyDiscountCode: flow.bound,
      removeDiscountCode: flow.bound,
      updateAddress: flow.bound,
      updateEmail: flow.bound,
      updateShippingMethod: flow.bound,
      // stripePaymentIntent: flow.bound,
      addressUnset: computed,
      hydrate: action,
      clearCheckoutUserErrors: action.bound
      // setStripeCardTokenData: action.bound
    });
  }

  get asJson() {
    return {
      cart: this.checkout,
      checkoutUserErrors: this.checkoutUserErrors,
      loading: this.loading,
      addressUnset: this.addressUnset
      // stripePaymentIntentData: this.stripePaymentIntentData
      // stripeCardTokenData: this.stripeCardTokenData
    };
  }

  get addressUnset() {
    return (
      this.checkout?.shippingAddress?.formattedArea?.includes('init-create') &&
      this.checkout?.shippingAddress?.firstName === null
    );
  }

  hydrate(data?: CheckoutHydration) {
    if (data) {
      this.checkout = data.checkout;
      this.checkoutUserErrors = data.checkoutUserErrors;
    }
  }

  clearCheckoutUserErrors() {
    this.checkoutUserErrors = undefined;
    return;
  }

  // setStripeCardTokenData(token: CheckoutHydration['stripeCardTokenData']) {
  //   // this.stripeCardTokenData = token;
  //   return;
  // }

  // *stripePaymentIntent(body?: Partial<UseStripeIntentBody>) {
  //   this.loading = true;

  //   const res: UsePaymentIntentData | undefined = yield useStripeIntent({
  //     payment_intent_id: body?.payment_intent_id ?? this.stripePaymentIntentData?.id,
  //     amount: Math.ceil(
  //       body?.amount
  //         ? body?.amount * 100
  //         : undefined ??
  //             (this.checkout?.paymentDue?.amount && typeof +this.checkout?.paymentDue?.amount === 'number'
  //               ? +this.checkout?.paymentDue?.amount * 100
  //               : 0)
  //     ),
  //     ...body
  //   });

  //   this.stripePaymentIntentData = res;

  //   this.loading = false;

  //   return res;
  // }

  *createCheckout(body: CreateCheckoutBody) {
    this.loading = true;

    // Left here incase we have issues with create a new checkout every time
    // if (this.checkout) {
    //   this.loading = false;
    //   return { checkout: this.checkout, checkoutUserErrors: this.checkoutUserErrors };
    // }

    // Left incase we need to get checkout that exists
    // const setIDInLocalStorage = (checkoutId?: string | null) => {
    //   if (typeof window !== 'undefined' && checkoutId) {
    //     window.localStorage.setItem(
    //       'aeribella-checkout-id',
    //       JSON.stringify({
    //         timeStamp: new Date(),
    //         checkoutID: checkoutId
    //       })
    //     );
    //   }
    // };

    // if (typeof window !== 'undefined') {
    //   const clientCheckoutID = window.localStorage?.getItem('aeribella-checkout-id');
    //   const parsedClientCheckoutID = clientCheckoutID && JSON.parse(clientCheckoutID);
    //   const olderThan1Day = parsedClientCheckoutID && isExpiredDate(parsedClientCheckoutID.timeStamp, 'days', 1);

    //   if (clientCheckoutID && !olderThan1Day) {
    //     const checkoutByID: CheckoutData | undefined = yield this.getCheckoutById(parsedClientCheckoutID?.checkoutID);

    //     const existingLineItems = checkoutByID?.lineItems?.map(({ id }) => id);

    //     if (existingLineItems && existingLineItems?.length > 0) {
    //       yield this.removeLineItem({
    //         checkoutId: checkoutByID?.id ?? '',
    //         lineItemIds: [existingLineItems[0]]
    //       });
    //     }

    //     yield this.addLineItems({
    //       checkoutId: parsedClientCheckoutID?.checkoutID,
    //       lineItems: body.lineItems
    //     });

    //     setIDInLocalStorage(checkoutByID?.id);
    //     return checkoutByID;
    //   }
    // }

    const res: CheckoutRes | undefined = yield createCheckout(body);

    if (res instanceof Error) {
      this.rootStore.setGlobalError(apiErrorMessage('creating your checkout'));
      this.loading = false;
      throw res;
    }

    // setIDInLocalStorage(res?.checkout?.id);

    this.checkout = res?.checkout ?? this.checkout ?? undefined;
    this.checkoutUserErrors = [...(this.checkoutUserErrors || []), ...(res?.checkoutUserErrors || [])] ?? undefined;

    this.loading = false;

    return res;
  }

  *getCheckoutById(id: string) {
    this.loading = true;

    const checkout: CheckoutData | undefined = yield getCheckoutById(id);

    if (checkout instanceof Error) {
      this.rootStore.setGlobalError(apiErrorMessage('retrieving your checkout ID'));
      this.loading = false;
      throw checkout;
    }

    this.checkout = checkout ?? this.checkout;
    this.checkoutUserErrors = undefined;

    this.loading = false;
    return checkout;
  }

  *addGiftCard(body: AddGiftCardBody) {
    this.loading = true;

    const res: CheckoutRes | undefined = yield addGiftCard(body);

    if (res instanceof Error) {
      this.rootStore.setGlobalError(apiErrorMessage('adding gift card'));
      this.loading = false;
      throw res;
    }

    this.checkout = res?.checkout ?? this.checkout ?? undefined;
    this.checkoutUserErrors = [...(this.checkoutUserErrors || []), ...(res?.checkoutUserErrors || [])] ?? undefined;

    this.loading = false;
    return res;
  }

  *removeGiftCard(body: RemoveGiftCardBody) {
    this.loading = true;

    const res: CheckoutRes | undefined = yield removeGiftCard(body);

    if (res instanceof Error) {
      this.rootStore.setGlobalError(apiErrorMessage('removing gift card'));
      this.loading = false;
      throw res;
    }

    this.checkout = res?.checkout ?? this.checkout ?? undefined;
    this.checkoutUserErrors = [...(this.checkoutUserErrors || []), ...(res?.checkoutUserErrors || [])] ?? undefined;

    this.loading = false;
    return res;
  }

  *addLineItems(body: AddLineItemsBody) {
    this.loading = true;

    const existingLineItems = body?.lineItems?.map(({ variantId }) => variantId);
    const alreadyExistingLineItems = this.checkout?.lineItems
      ?.filter(({ variant }) => existingLineItems?.includes(variant?.id))
      ?.map(({ id }) => id);

    if (alreadyExistingLineItems && alreadyExistingLineItems.length > 0) {
      yield this.removeLineItem({ checkoutId: this.checkout?.id ?? '', lineItemIds: alreadyExistingLineItems });
    }

    const res: CheckoutRes | undefined = yield addLineItems(body);

    if (res instanceof Error) {
      this.rootStore.setGlobalError(apiErrorMessage('adding a line item to your checkout'));
      this.loading = false;
      throw res;
    }

    this.checkout = res?.checkout ?? this.checkout ?? undefined;
    this.checkoutUserErrors = [...(this.checkoutUserErrors || []), ...(res?.checkoutUserErrors || [])] ?? undefined;

    this.loading = false;
    return res;
  }

  *removeLineItem(body: RemoveLineItemBody) {
    this.loading = true;

    const res: CheckoutRes | undefined = yield removeLineItem(body);

    if (res instanceof Error) {
      this.rootStore.setGlobalError(apiErrorMessage('removing a line item from your checkout'));
      this.loading = false;
      throw res;
    }

    this.checkout = res?.checkout ?? this.checkout ?? undefined;
    this.checkoutUserErrors = [...(this.checkoutUserErrors || []), ...(res?.checkoutUserErrors || [])] ?? undefined;

    this.loading = false;
    return res;
  }

  *applyDiscountCode(checkoutId: string, discountCode: string) {
    this.loading = true;

    const res: CheckoutRes | undefined = yield applyDiscountCode(checkoutId, discountCode);

    if (res instanceof Error) {
      this.rootStore.setGlobalError(apiErrorMessage('adding discount code'));
      this.loading = false;
      throw res;
    }

    this.checkout = res?.checkout ?? this.checkout ?? undefined;
    this.checkoutUserErrors = [...(this.checkoutUserErrors || []), ...(res?.checkoutUserErrors || [])] ?? undefined;

    this.loading = false;
    return res;
  }

  *removeDiscountCode(checkoutId: string) {
    this.loading = true;

    const res: CheckoutRes | undefined = yield removeDiscountCode(checkoutId);

    if (res instanceof Error) {
      this.rootStore.setGlobalError(apiErrorMessage('removing discount code'));
      this.loading = false;
      throw res;
    }

    this.checkout = res?.checkout ?? this.checkout ?? undefined;
    this.checkoutUserErrors = [...(this.checkoutUserErrors || []), ...(res?.checkoutUserErrors || [])] ?? undefined;

    this.loading = false;
    return res;
  }

  *updateAddress(body: UpdateAddressBody) {
    this.loading = true;

    try {
      const res: CheckoutRes | undefined = yield updateAddress(body);

      if (res instanceof Error) {
        this.rootStore.setGlobalError(apiErrorMessage('updating your address'));
        this.loading = false;
        throw res;
      }

      this.checkout = res?.checkout ?? this.checkout ?? undefined;
      this.checkoutUserErrors = [...(this.checkoutUserErrors || []), ...(res?.checkoutUserErrors || [])] ?? undefined;

      this.loading = false;
      return res;
    } catch (e) {
      this.loading = false;
      throw e;
    }
  }

  *updateEmail(checkoutId: string, email: string) {
    this.loading = true;

    const res: CheckoutRes | undefined = yield updateEmail(checkoutId, email);

    if (res instanceof Error) {
      this.rootStore.setGlobalError(apiErrorMessage('updating your email address'));
      this.loading = false;
      throw res;
    }

    this.checkout = res?.checkout ?? this.checkout ?? undefined;
    this.checkoutUserErrors = [...(this.checkoutUserErrors || []), ...(res?.checkoutUserErrors || [])] ?? undefined;

    this.loading = false;
    return res;
  }

  *updateShippingMethod(body: UpdateShippingMethodBody) {
    this.loading = true;

    const res: CheckoutRes | undefined = yield updateShippingMethod(body);

    if (res instanceof Error) {
      this.rootStore.setGlobalError(apiErrorMessage('updating the shipping method'));
      this.loading = false;
      throw res;
    }

    this.checkout = res?.checkout ?? this.checkout ?? undefined;
    this.checkoutUserErrors = [...(this.checkoutUserErrors || []), ...(res?.checkoutUserErrors || [])] ?? undefined;

    this.loading = false;
    return res;
  }
}
