import { NextApiRequest, NextApiResponse } from 'next';
import { BASE_URL, apiError } from '../../utils';
import { shopifyStoreFront, transformCollection } from '../../utils/shopifyApi';

const collectionByHandleQuery = `
query CollectionByHandle($handle: String!, $first: Int = 16, $before: String, $after: String, $sortBy: ProductCollectionSortKeys, $filters: [ProductFilter!], $reverse: Boolean = false) {
  collection(handle: $handle) {
    id
    title
    handle
    products(first: $first, before: $before, after: $after, sortKey: $sortBy, filters: $filters, reverse: $reverse) {
      edges {
        node {
          id
          title
          handle
          totalInventory
          priceRange {
            minVariantPrice {
              amount
            }
          }
          featuredImage {
            url
            altText
            id
          }
        }
      }
    }
  }
}
`;

const shopifyCollectionByHandle = async (req: NextApiRequest, res: NextApiResponse) => {
  try {
    const { collectionHandle } = req?.query;

    const response = await shopifyStoreFront(collectionByHandleQuery, { handle: collectionHandle });

    res.status(200);
    res.json(response);
  } catch (error) {
    apiError({
      errorInText: 'UPDATE COLLECTION BY HANDLE',
      error,
      res
    });
  }
};

export enum CollectionHandles {
  newOrchidArrivals = 'new-arrivals'
}

export const getShopifyCollectionByHandle = async (collectionHandle: string) => {
  try {
    const response = await fetch(`${BASE_URL}/api/shopify/products/collection?collectionHandle=${collectionHandle}`);

    const resJson = await response?.json();

    return resJson ? transformCollection(resJson) : null;
  } catch (e: any) {
    console.error('getShopifyCollectionByHandle failed', e);
    if (e) return e;
  }
};

export default shopifyCollectionByHandle;
