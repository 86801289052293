import * as React from 'react';
import { Box, PolymorphicComponentProps } from './Box';
import { Text, TextProps } from './Text';
import { ButtonVariants, buttonInvariant, buttonRecipe } from './button.css';
import { Spinner, SpinnerProps } from './spinner';

type StyleProps = {} & ButtonVariants;

type UVariant<T = StyleProps> = T extends ButtonVariants ? Omit<T, 'variant' | 'size'> : never;

type ButtonOwnProps = UVariant & {
  disabled?: boolean;
  size?: StyleProps['size'];
  variant?: 'unstyled' | StyleProps['variant'];
  isLoading?: boolean;
  overrideDefaultLoader?: boolean;
  textProps?: TextProps<'span'>;
  loaderSize?: SpinnerProps['size'];
  spinnerColor?: SpinnerProps['color'];
  form?: string;
};

export type ButtonProps<E extends React.ElementType> = PolymorphicComponentProps<E, ButtonOwnProps>;

const defaultElement = 'button';

export const Button: <E extends React.ElementType = typeof defaultElement>(
  props: ButtonProps<E>
) => React.ReactElement | null = React.forwardRef(
  <E extends React.ElementType = typeof defaultElement>(
    {
      as,
      size,
      variant,
      disabled,
      isLoading,
      children,
      style,
      display,
      textProps,
      fontSize = 'md',
      loaderSize,
      textTransform = 'capitalize',
      form,
      spinnerColor = 'white',
      className,
      overrideDefaultLoader = false,
      ...restProps
    }: ButtonProps<E>,
    ref: typeof restProps.ref
  ) => {
    // Use `variant='unstyled'` to bypass recipe. This allows for full customization
    // via style props. Note that the `unstyled` variant is not part of the recipe and
    // is only used here as a flag to bypass the recipe.
    const noVariant = variant === 'unstyled';
    const isDisabled =
      ['button', 'fieldset', 'optgroup', 'option', 'select', 'textarea', 'input'].includes(
        typeof as === 'string' ? as : defaultElement
      ) &&
      (!!isLoading || !!disabled);
    const buttonClassName = noVariant ? buttonInvariant : buttonRecipe({ size, variant });

    return (
      // @ts-ignore
      <Box
        className={[buttonClassName, className].join(' ')}
        as={as ?? defaultElement}
        type={restProps?.as === 'button' ? 'button' : undefined}
        display={display ?? 'inline-flex'}
        position="relative"
        alignItems="center"
        justifyContent="center"
        height="44px"
        paddingX="12px"
        bg="transparent"
        border="none"
        fontSize={fontSize}
        textTransform={textTransform}
        style={{ ...style, opacity: isDisabled ? '0.5' : '1' }}
        {...restProps}
        {...(isDisabled ? { disabled: isDisabled } : {})}
        ref={ref}>
        {isLoading && !overrideDefaultLoader ? (
          <Spinner size={loaderSize} color={spinnerColor} />
        ) : typeof children === 'string' ? (
          <Text as="span" color="inherit" fontSize="inherit" fontWeight="inherit" {...textProps}>
            {children}
          </Text>
        ) : (
          children
        )}
      </Box>
    );
  }
);
